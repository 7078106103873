.ico {
    background-image: url('../assets/ico_Start.svg');
    width: 420px;
    height: 160px;
    background-size: cover;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 4%;
    margin-top: -20px;
}
.textCenter {
    margin-top: 30px;
    text-align: center;
    font-size: 25px;
}
.imgs {
    margin-top: 65px;
    text-align: center;
    font-size: 20px;
    padding-bottom: 105px;
}
.imgs img {
    margin-top: 20px;
    margin-left: 5px;
}

@media (max-width: 500px) {
    .ico {
        background-size: 300px 300px;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        margin-left: 65%;
    }
    .imgs {
        padding-bottom: 50px;
    }
}
@media (max-width: 376px) {
    .img {
        position: relative;
        left: 10% !important;
    }
}
@media (max-width: 321px) {
    .ico {
        background-size: 300px 300px;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        margin-left: 68%;
    }
}