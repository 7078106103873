.ico-shops{
    background-image: url('../assets/ico_Sklepy.svg');
    width: 420px;
    height:160px;
    background-size: cover;
    /*Krawczyk zmieniłem Ci to zeby było na środku zawsze //Buczek */
    margin-left: 50%;
    transform: translateX(-50%);
}
.shops-txt{
    text-align: center;
    width: 100%;
    font-size: 25px;
    padding-top: 42px;
}
.shops-container{
    background-color: #F3F3F3;
}

.shops-slider-container{
    width: 100%;
    margin-left: -50px;
    text-align: center;
}

.shops{
    height:301px;
    width:1340px;
    margin-top: 97px;
    padding-bottom: 97px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.shop{
    background-image: url('../assets/bg_Sklepy.svg');
    background-repeat: no-repeat;
    height:301px;
    width:270px !important;
}
.shop-item{
    text-align: center;
    font-size: 15px;
}
.shop-item-first{
    margin-top: 45%;
    font-size: 15px;
}

.slider-shops-controls-arrow{
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
    top: 30%;
    z-index: 12121221;
}

.helper{
    top: -70%;
}

/*Dodałem to tak o byście nie mieli problemów ze stylowaniem na telefon bo sie pieprzylo przez orzmiar stały - nie traktujcie tego jako gotowe czy cos - Adam*/
@media screen and (max-width: 1400px) {
    .shops {
        width: 100%;
    }
}
@media (max-width: 500px ) {
    .ico-shops {
        background-size: 300px 300px;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        margin-left: 65%;
    }
    .shops {
        padding-bottom: 20px;
        margin-bottom: 0px;
    }
    .shop {
        position: relative;
        left: -1%;
    }
    .shops-container {
        margin-top: -100px;
    }
}
@media (max-width: 376px) {
    .shop {
        position: relative;
        left: -5%;
    }
}
@media (max-width: 321px) {
    .ico-shops {
        background-size: 300px 300px;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        margin-left: 68%;
    }
    .shop {
        position: relative;
        width: 250px !important;
        left: -16%;
        background-size: 100%;
    }
    p, h3 {
        position: relative;
        left: -1%;
        top: -1%;
        font-size: 13px !important;
    }
}

