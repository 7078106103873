.login-window-container{
    width: 500px;
    height: 270px;
    background-color: #f8f8f8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 2px solid #d6d6d6;
    border-radius: 20px;
    font-family: Roboto;
}

.login-window-title{
    font-size: 30px;
    color: #5B5B5B;
    font-weight: bolder;
    text-align: center;
    margin-top: 20px;
}

.login-window-input{
    width: 80%;
    height: 45px;
    margin: 0 auto;
    margin-top: 20px;
}

.login-window-label{
    width: 30%;
    height: 45px;
    float: left;
    line-height: 45px;
    vertical-align: middle;
    text-align: right;
    font-size: 24px;
    color: #5B5B5B;
}

.login-window-prompt{
    width: 70%;
    height: 45px;
    float: left;
    line-height: 45px;
    vertical-align: middle;
    text-align: center;
}

.login-window-prompt input{
    width: 90%;
    height: 30px;
    font-size: 24px;
}

.login-window-button{
    width: 100px;
    height: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    font-size: 25px;
    color: #5B5B5B;
    border: 2px solid #c5c5c5;
    margin-top: 13px;
}

.login-window-button:hover{
    background-color: #aaaaaa;
    border-color: #555555;
    color: #ffffff;
    cursor: pointer;
}

.managment-panel-container{
    width: 700px;
    min-height: 150px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: Roboto;
    border: 2px solid #d6d6d6;
    border-bottom: none;
}

.managment-panel-header{
    width: 100%;
    height: 50px;
    background-color: #f8f8f8;
    border-bottom: 2px solid #d6d6d6;
}

.managment-panel-header-label{
    width: 80%;
    height: 50px;
    float: left;
    font-size: 30px;
    text-align: center;
    color: #5b5b5b;
    line-height: 50px;
    vertical-align: middle;
    font-weight: bold;
}

.managment-panel-header-logout{
    width: 19%;
    height: 50px;
    float: left;
    text-align: center;
    font-size: 25px;
    color: #5b5b5b;
    line-height: 50px;
    vertical-align: middle;
    border-left: 2px solid #d6d6d6;
}

.managment-panel-header-logout span{
    cursor: pointer;
}

.managment-panel-create{
    width: 100%;
    height: 100px;
    border-bottom: 2px solid #d6d6d6;
}

.managment-panel-create-header{
    font-size: 22px;
    color: #5b5b5b;
    text-align: center;
    margin-top: 5px;
}

.managment-panel-create-item{
    margin-top: 5px;
    width: 25%;
    height: 60px;
    float: left;
    text-align: center;
}

.managment-panel-create-button{
    width: 70%;
    height: 30px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    line-height: 30px;
    vertical-align: 30px;
    color: #5B5B5B;
    border: 2px solid #c5c5c5;
}

.managment-panel-create-button:hover{
    background-color: #aaaaaa;
    border-color: #555555;
    color: #ffffff;
    cursor: pointer;
}

.managment-panel-list{
    overflow: auto;
}

.managment-panel-list-item{
    width: 100%;
    height: 100px;
    /*background-color: red;*/
    border-bottom: 2px solid #c5c5c5;
}

.labels-container{
    width: 85%;
    height: 80px;
    float: left;
    font-size: 18px;
    position: relative;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
}

.button-container{
    width: 15%;
    height: 80px;
    float: left;
    margin-top: 10px;
}

.list-button{
    width: 50px;
    height: 50px;
    position: relative;
    top: 50%;
    left: 35%;
    transform: translateY(-50%);
    background-image: url("./assets/delete_ico.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}