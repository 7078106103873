.slider-component-container{
    width: 100%;
    font-family: Roboto;
    /*min-height: 300px;*/
}

.slider-image-container{
    height: 935px;
    width: 100%;
}

.slider-item{
    height: 935px;
    width: 100%;
}

.slider-image-title{
    position: relative;
    font-size: 40px;
    color: white;
    text-align: center;
    top: -71%;
}

.slider-image-text{
    position: relative;
    font-size: 24px;
    color: white;
    text-align: center;
    top: -65%;
}

.slider-buttons{
    position: relative;
    top: -62%;
    text-align: center;
}

.slider-buttons-button{
    height: 83px;
    width: 255px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
}

.play{
    background-image: url('../assets/btn_img_Google Play.svg');
}

.store{
    background-image: url('../assets/btn_img_App Store.svg');
    margin-left: 16px;
}

.slider-phone-container{
    min-height: 420px;
    background-color: #F3F3F3;
}

.slider-phone{
    /*background-image: url('../assets/img_Jak grac_1.png');*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 427px;
    width: 980px;
    position: relative;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1001;
}

.slider-phone-item{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 427px;
    width: 980px;
    border: none;
}

.jak-grac-1{
    background-image: url("../assets/img_Jak grac_1.png");
}

.jak-grac-2{
    background-image: url("../assets/img_Jak grac_2.png");
}

.jak-grac-3{
    background-image: url("../assets/img_Jak grac_3.png");
}

/*.slider-phone-item2{*/
/*width: 980px;*/
/*transform: translateY(-12.5%);*/
/*}*/

.slider-controls{
    width: 1000px;
    min-height: 70px;
    position: relative;
    top: 10%;
}

.slider-controls div{
    display: inline-block;
}

.slider-controls-arrow{
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.slider-controls-text-container{
    width: calc(100% - 140px);
    min-height: 70px;
}

.slider-controls-text{
    width: 550px;
    min-height: 70px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    word-break: normal;
    text-align: center;
    font-size: 20px;
    color: #5B5B5B;
}

.prev{
    background-image: url("../assets/btn_prev.svg");
    float: left;
}

.next{
    background-image: url("../assets/btn_next.svg");
    float: right;
}
/*laptop*/
@media (max-width: 1024px) {
    .slider-item{
        height: 100%;
        width: 100%;
    }
    .slider-image-container{
        height: 468px;
        width: 100%;
    }
    .slider-buttons{
        position: relative;
        top: -62%;
        text-align: center;
    }

    .slider-buttons-button{
        height: 43px;
        width: 128px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: inline-block;
    }
    .slider-image-title{
        margin-top: 20px;
    }

    .play{
        background-image: url('../assets/btn_img_Google Play.svg');
    }

    .store{
        background-image: url('../assets/btn_img_App Store.svg');
        margin-left: 8px;
    }
    .slider-phone-container{
        min-height: 210px;
        background-color: #F3F3F3;
    }

    .slider-phone{
        /*background-image: url('../assets/img_Jak grac_1.png');*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 238px;
        width: 550px;
        position: relative;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1001;
        margin-top: 10px;
    }
    .slider-phone-item{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 238px;
        width: 490px;
        border: none;
    }
    .slider-controls{
        width: 100%;
        min-height: 35px;
        position: relative;
        top: 10%;
    }
    .slider-controls-arrow{
        height: 35px;
        width: 35px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
    }

    .slider-controls-text-container{
        width: calc(100% - 70px);
        min-height: 35px;
    }

    .slider-controls-text{
        width: 350px;
        min-height: 35px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        word-break: normal;
        text-align: center;
        font-size: 12px;
        color: #5B5B5B;
    }
    .slider-image-title{
        position: relative;
        font-size: 27px;
        color: white;
        text-align: center;
        top: -71%;
    }
    .slider-image-text{
        position: relative;
        font-size: 20px;
        color: white;
        text-align: center;
        top: -65%;
    }
    .slider-controls-text{
        font-size: 14px;
    }
}
/*tablet*/
@media (max-width: 768px) {
    .slider-item{
        height: 100%;
        width: 100%;
    }
    .slider-image-container{
        height: 374px;
        width: 100%;
    }
    .slider-buttons{
        position: relative;
        top: -62%;
        text-align: center;
    }
    .slider-image-title{
       margin-top: 40px;
        font-size: 22px !important;
        top: -81% !important;
    }
    .slider-image-text{
        top: -75% !important;
    }
    .slider-buttons{
        top: -72% !important;
    }
    .slider-buttons-button{
        width: 120px !important;
        height: 40px !important;
    }

    .slider-buttons-button{
        height: 34px;
        width: 102px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: inline-block;

    }

    .play{
        background-image: url('../assets/btn_img_Google Play.svg');
    }

    .store{
        background-image: url('../assets/btn_img_App Store.svg');
        margin-left: 6px;
    }
    .slider-phone-container{
        min-height: 168px;
        background-color: #F3F3F3;
        padding-bottom: 100px;
    }

    .slider-phone{
        /*background-image: url('../assets/img_Jak grac_1.png');*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 190px;
        width: 90% !important;
        position: relative;
        /*left: 0% !important;*/
        transform: translateY(-50%) translateX(-45%) !important;
        z-index: 1001;
        margin-top: 0 !important;

    }
    .slider-phone-item{
        background-repeat: no-repeat;
        background-size: contain !important;
        background-position: center;
        height: 270px;
        width: 90% !important;
        border: none;
    }
    .jak-grac-3{
        background-size: cover !important;
    }
    .slider-controls{
        width: 90%;
        min-height: 28px;
        position: relative;
        top: 10%;
        left: 50%;
        transform: translateX(-55%);
    }
    .slider-controls-arrow{
        height: 40px;
        width: 40px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
    }

    .slider-controls-text-container{
        width: calc(100% - 80px);
        min-height: 28px;
    }

    .slider-controls-text{
        width: 90%;
        min-height: 28px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        word-break: normal;
        text-align: center;
        font-size: 15px;
        color: #5B5B5B;
    }
    .slider-image-title{
        position: relative;
        font-size: 20px;
        color: white;
        text-align: center;
        top: -71%;
    }
    .slider-image-text{
        position: relative;
        font-size: 18px;
        color: white;
        text-align: center;
        top: -65%;
    }
}

@media(max-width: 650px){
    .slider-image-text, .slider-buttons{
        display: none;
    }
    .slider-image-title{
        top: -77% !important;
    }
    .slider-item-container{
        height: 400px !important;
    }
    .slider-item{
        height: 400px !important;
    }

}

@media (max-width: 600px){
    .slider-item-container, .slider-item{
        height: 300px !important;
    }
    .slider-image-title{
        top: -60% !important;
    }
    .slider-phone{
        left: 0% !important;
        top: 0% !important;
        transform: translateX(5%) translateY(-70%)!important;
        width: 100% !important;
    }
}

@media (max-width: 520px){
    .slider-controls{
        top: -5% !important;
    }

    .slider-controls-text{
        width: 100% !important;
    }
}

/*phone L*/
@media (max-width: 426px) {
    .slider-item{
        height: 100%;
        width: 100%;
    }
    .slider-image-container{
        height: 187px;
        width: 100%;
    }
    .slider-buttons{
        position: relative;
        top: -62%;
        text-align: center;
    }

    .slider-buttons-button{
        height: 17px;
        width: 51px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: inline-block;
    }
    .slider-image-title{
        top: -100% !important;
    }

    .play{
        background-image: url('../assets/btn_img_Google Play.svg');
    }

    .store{
        background-image: url('../assets/btn_img_App Store.svg');
        margin-left: 3px;
    }
    .slider-phone-container{
        min-height: 84px;
        background-color: #F8F8F8;
        width: 100% !important;
    }

    .slider-phone{
        /*background-image: url('../assets/img_Jak grac_1.png');*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 150px !important;
        width: 100% !important;
        position: relative;
        left: 0% !important;
        transform: translateX(2.5%) translateY(50%) !important;
        z-index: 1001;
        margin-top: 30px;
        margin-bottom: 60px;
    }
    .slider-phone-item{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 145px !important;
        width: 95% !important;
        border: none;
    }
    .slider-controls{
        width: 85% !important;
        margin-left: 3px;
        min-height: 14px;
        position: relative;
        margin-top: 15px;
        top: 10%;
        /*left: 2.5%;*/
        /*display: none;*/
        /*transform: translateX(2.5%) !important;*/
    }
    .slider-controls-arrow{
        height: 25px !important;
        width: 25px !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
    }
    .jak-grac-3{
        background-size: 85% 180px !important;
    }

    .slider-controls-text-container{
        width: calc(100% - 50px) !important;
        min-height: 14px;
    }

    .slider-controls-text{
        width: 140px;
        min-height: 20px !important;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        word-break: normal;
        text-align: center;
        font-size: 10px;
        color: #5B5B5B;
    }
    .slider-image-title{
        position: relative;
        font-size: 12px;
        color: white;
        text-align: center;
        top: -71%;
    }
    .slider-image-text{
        position: relative;
        font-size: 10px;
        color: white;
        text-align: center;
        top: -65%;
    }
}
/*phone M*/
@media (max-width: 376px) {
    .slider-item{
        height: 100%;
        width: 100%;
    }
    .slider-image-container{
        height: 155px;
        width: 100%;
    }
    .slider-image-title{
        top: -140% !important;
    }
    .slider-buttons{
        position: relative;
        top: -62%;
        text-align: center;
    }

    .slider-buttons-button{
        height: 17px;
        width: 51px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: inline-block;
    }

    .play{
        background-image: url('../assets/btn_img_Google Play.svg');
    }

    .store{
        background-image: url('../assets/btn_img_App Store.svg');
        margin-left: 3px;
    }
    .slider-phone-container{
        min-height: 84px;
        background-color: #F8F8F8;
    }
    .slider-phone{
        /*background-image: url('../assets/img_Jak grac_1.png');*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 95px;
        width: 220px;
        position: relative;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1001;
        margin-top: 35px !important;
    }
    .slider-phone-item{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 95px;
        width: 196px;
        border: none;
    }
    .slider-controls{
        width: 200px;
        min-height: 14px;
        position: relative;
        top: 10%;
    }
    .slider-controls-arrow{
        height: 14px;
        width: 14px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
    }

    .slider-controls-text-container{
        width: calc(100% - 28px);
        min-height: 14px;
    }

    .slider-controls-text{
        width: 140px;
        min-height: 14px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        word-break: normal;
        text-align: center;
        font-size: 9px;
        color: #5B5B5B;
    }
    .slider-image-title{
        position: relative;
        font-size: 12px;
        color: white;
        text-align: center;
        top: -71%;
    }
    .slider-image-text{
        position: relative;
        font-size: 10px;
        color: white;
        text-align: center;
        top: -65%;
    }
}

@media(max-width: 321px){
    .next{
        position: relative;
        left: 1%;
        /*margin-left: 5px;*/
    }
}