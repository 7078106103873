.left {
    width: 40%;
    height: 100%;
    font-size: 15px;
    float:left;
}
.name {
    font-weight: bold;
    margin-left: 40px;
    padding-top: 25px;
}
.contact {
    margin-top: 20px;
    margin-left: 20px;
}
.box {
    margin-left: 20px !important;;
}
.boxUP {
    float: left;
    margin-left: 40px;
}
.boxDOWN {
    float: left;
    margin-left: 21px;
}
.center {
    float: left;
    width: 20%;
    height: 100%;
    text-align: center;
}
.btn {
    margin-top: 25px;
    font-size: 15px;
    text-decoration: underline;
    font-weight: bold;
    postion: relative;
}
.right {
    float: right;
}
.copyright {
    /*margin-left: 300px;*/
    margin-right: 56px;
    margin-top: 25px;
}

@media (max-width: 376px) {
    .copyright {
        font-size: 6px !important;
    }
    .boxDOWN {
        marign-left: 30px !important;
    }
    .center {
        width: 22% !important;
    }
}
/*tutaj tego media 321px dodałem tylko */
@media (max-width: 321px) {
    .center {
        float: right;
        margin-right: 15%;
    }
    .copyright {
        position: relative;
        top: 12px;
    }
}
@media (max-width: 426px) {
    .name {
        margin-left: 10px;
        font-size: 12px
    }
    .contact {
        margin-left: 5px;
    }
    .left, .btn {
        font-size: 8px !important;
    }
    .boxDOWN, .boxUP {
        font-size: 6px !important;
    }
    .copyright {
        margin-right: 10px !important;
        font-size: 7px !important;
    }
    .center {
        width: 18%;
    }
    .boxUP {
        margin-left: 12px !important;
    }
    .box {
        margin-left: 5px !important;
    }
    .boxDOWN {
        margin-left: 5px !important;
    }
    .btn {
        margin-left: 25px;
    }

}
@media (max-width: 768px) {
    .boxUP, .boxDOWN {
        font-size: 13px;
    }
    .copyright {
        font-size: 13px;
        margin-right: 30px;
    }
    .boxUP {
        margin-left: 35px;
    }
    .boxDOWN {
        margin-left: 20px;
    }
}