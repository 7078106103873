.map-ico {
    background-image: url('../assets/ico_Mapa.svg');
    width: 420px;
    height:160px;
    background-size: cover;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 3%;
    margin-top: -80px;
}

.text {
    text-align: center;
    font-size: 25px;
    margin-bottom: 40px;
}
.map {
    background-image: url("../assets/img_Mapa.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 630px;
}

@media (max-width: 376px) {

}
@media (max-width: 426px) {
    .map {
        background-size: 700px 350px;
        background-position: -165px;
        height: 250px !important;
    }
}
/*tutaj porobic !!!*/
@media (min-width: 1921px) {
    .map {
        background-size: 1920px 630px;
        background-position: center;
        /*margin-top: 100px;*/

    }
}
@media (max-width: 768px) {
    .map {
        height: 300px;
    }
}
@media (max-width: 1024px) {
    .map {
        height: 400px;
        margin-left: 0 !important;
    }
}
@media (max-width: 1450px) {
    .map {
        margin-left: 0px;
    }
}
@media (max-width: 500px) {
    .map-ico {
        background-size: 300px 300px;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        margin-left: 65%;
    }
}
@media (max-width: 321px) {
    .map-ico {
        background-size: 300px 300px;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        margin-left: 68%;
    }
}