*{
    margin: 0;
    padding: 0;
    font-family: Roboto;
}

header{
    width: 100%;
    background-color: blue;
}

section{
    width: 100%;
}

article{
    background-color: #F3F3F3;
    min-height: 100px;
    color: #5B5B5B;
}

footer{
    width: 100%;
    height: 125px;
    background-color: #EEEEEE;
}