.top-bar-container{
    height: 140px;
    background-color: #FFFFFF;
}

.top-bar-menu{
    font-weight: bold;
    color: #5B5B5B;
    vertical-align: middle;
    line-height: 140px;
    float: right;
    margin-right: 35px;
}

.top-bar-menu ul li{
    display: inline-block;
    margin-right: 24px;
}

.image-container{
    z-index: 5212;
    width: 171px;
    height: 242px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('../assets/img_Logo.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 27px;
}

.burger{
    display: none;
}

.sidenav {
    height: 100%;
    width: 0px;
    position: fixed;
    z-index: 1123123;
    top: 0;
    left: 0;
    background-color: #F8F8F8;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    border-right: none;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #333333;
    display: block;
    transition: 0.3s;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media(max-width: 1024px){
    .top-bar-menu{
        float: left;
        margin-right: 0px;
        margin-left: 35px;
    }
    .image-container{
        left: 100%;
        transform: translateX(-150%);
        width: 150px;
        height: 225px;
        margin-top: 20px;
    }
}

@media(max-width: 768px){
    .top-bar-menu{
        font-size: 15px;
    }
    .image-container{
        width: 120px;
        height: 180px;
    }
}

@media(max-width: 425px){
    .top-bar-container{
        height: 100px;
    }
    .top-bar-menu{
        display: none;
    }
    .image-container{
        transform: translateX(-130%);
        width: 100px;
        height: 150px;
    }
    .burger{
        display: block;
        width: 40px;
        height: 30px;
        position: absolute;
        margin-top: 35px;
        margin-left: 30px;
    }
    .burger-strip{
        background-color: #232323;
        width: 100%;
        height: 5px;
        position: absolute;
    }
    .second{
        top: 50%;
        transform: translateY(-50%);
    }
    .third{
        top: 100%;
        transform: translateY(-5px);
    }
    .sidenav ul{
        margin-left: 20px;
        list-style: none;
        margin-top: 10px;
    }
    .sidenav ul li {
        color: #5b5b5b;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 20px;
    }
}

@media(max-width: 375px){
    .image-container{
        transform: translateX(-110%);
    }
}